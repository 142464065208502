import React from 'react';
import { useRouter } from 'next/router';

import { getEnableTrendingDFP } from '../../../hooks/useFirebase';
import { useMoengageEventTracker } from '../../../hooks/useMoengage';

import styles from './AIBanner.module.scss';

function AIBanner() {
  const [aiBannerFlag, setAiBannerFlag] = React.useState(false);
  const trackMoengageEvent = useMoengageEventTracker();
  const router = useRouter();
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getEnableTrendingDFP();
        setAiBannerFlag(data);
      } catch (error) {
        // error
      }
    };

    fetchData();
  }, [aiBannerFlag]);

  const handleStartNow = () => {
    try {
      trackMoengageEvent('ncert_summary_tapped', {
        screen_source: 'home',
        position: 'ai-banner',
      });
    } catch (error) {
      /* empty */
    }
    router.push('/studyiq-ai');
  };

  if (!aiBannerFlag) return null;

  return (
    <div className={styles.wrapper}>
      <div className={styles.bannerOuterWrapper}>
        <span className={styles.bannerTitle}>
          Unlock your learning with the power of
          <span className={styles.bannerSIQText}> StudyIQ AI</span>
        </span>
        <span className={styles.bannerInnerText}>
          Empower your UPSC preparation with the AI-generated chapter summaries
          from NCERT books (Class 6-12).
        </span>
        <button
          type="button"
          className={styles.bannerStartNow}
          onClick={handleStartNow}
          data-testid="ai-banner-start-now"
        >
          Start Now
        </button>
      </div>
    </div>
  );
}

export default AIBanner;
